import React, { useState, useEffect } from "react";
import Question from "../question/Question.jsx";
import { useNavigate } from "react-router-dom";
import "./style/quiz.css";
import useQuestion from "../strapi/Question.jsx";
import Answer from "../answer/Answer.jsx";
// Import the spinner CSS
import "./style/spinner.css";
import Spinner from "../spinner/Spinner.jsx";

const Quiz = () => {
  const navigate = useNavigate();

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState("");
  const handleAnswer = (answerScore) => {
    if ((answerScore == 0) | (answerScore == 1)) {
      setScore(score + answerScore);
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const { fetchedData, loading, error } = useQuestion();
  useEffect(() => {
    console.log("fetchedData:", fetchedData);
  }, [fetchedData]);
  if (loading || !fetchedData) {
    return (
      // <div className="spinner-overlay">
      //   <div className="spinner"></div>
      // </div>
      <Spinner />
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="col-lg-12 col-md-10 quiz-container">
      <div id="answers">
        {currentQuestion < fetchedData.data.length ? (
          <Question
            question={fetchedData.data[currentQuestion]?.attributes.question}
            answers={[
              {
                image:
                  "https://caelusium-local.agileexlab.net" +
                  fetchedData.data[currentQuestion]?.attributes.answer_image_0
                    .data.attributes.url,
                score: 0,
              },
              {
                image:
                  "https://caelusium-local.agileexlab.net" +
                  fetchedData.data[currentQuestion]?.attributes.answer_image_1
                    .data.attributes.url,
                score: 1,
              },
            ]}
            handleAnswer={handleAnswer}
          />
        ) : (
          // <div>
          //   <Answer answerCode={score} />
          // </div>
          navigate("/result", { state: { score } })
        )}
      </div>
    </div>
  );
};

export default Quiz;
