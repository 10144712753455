// import error from '../../assets/img/error.webp'

// function to handle every dynamic image reference
function ImageHandler(urlBaseName, media, index) {
  const types = ['thumbnail', 'small', 'normal', 'medium', 'large']
  let url;
  let size = types[index]
  if (media === undefined) {
    console.log("media is undefined.")
    return ""
  }
  else {
    let path = media[size]['url']
    // use next lower resolution image if current target image url is ''
    while (path === '' && index > 0) {
      index--;
      size = types[index];
      path = media[size]['url']
    }

    if (path === '') {
      // use error image if all url are ''
      url = ""
    } else {
      url = urlBaseName + path
    }

    return url
  }

}

export default ImageHandler