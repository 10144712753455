import React from "react";
import "./style/landing.css";
import { Card } from "../card/Card";
import { useNavigate } from "react-router-dom";

export const Landing = () => {
  const navigate = useNavigate();

  const handleRedirectHome = () => {
    navigate("/");
  };

  const handleRedirectQuiz = () => {
    navigate("/quiz");
  };

  const handleRedirectInstagram = () => {
    window.open(
      "https://www.instagram.com/caelusium/?igshid=NzZlODBkYWE4Ng%3D%3D"
    );
  };

  const handleRedirectAboutUs = () => {
    navigate("/aboutUs");
  };

  return (
    <div className="texim-ai">
      <div className="navbar-logo-left">
        <div className="navbar-container">
          <div className="navbar-content">
            <div className="brand-logos">
              <div className="text-wrapper" onClick={handleRedirectHome}>
                <div className="company-logo logo-wrapper"></div>
              </div>
            </div>
            <div className="navbar-menu">
              <div className="navbar-link">
                <div className="navbar-div">算命</div>
              </div>
              <div className="navbar-link">
                <div className="navbar-div">塔羅</div>
              </div>
              <div className="navbar-link">
                <div className="navbar-div" onClick={handleRedirectAboutUs}>
                  關於我地
                </div>
              </div>
              <div className="navbar-button">
                <div className="text-wrapper-2" onClick={handleRedirectQuiz}>
                  開始測驗
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-section">
        <div className="div-2">
          <div className="title">
            <p className="text-wrapper-3">次世代塔羅占卜</p>
          </div>
          <p className="p">
            {/* 凡人不可貌相，天機不可泄漏。<br/> */}
            凡人可以貌相, 天機不可泄漏 <br />
            跟住我哋，攞個準確嘅塔羅占卜，就可以揭開你人生嘅神秘面紗啦！我哋嘅塔羅師唔止準確，仲有幽默感，一齊嚟瞓覺都可以笑到肚痛！你唔知自己未來會點，我哋就係你嘅神秘導航，帶你去探索運氣大洋！唔好錯過，快啲嚟挖掘屬於你嘅塔羅之旅啦！
          </p>
        </div>
        <div className="card-animation" />
        <div className="bg">
          <Card />
        </div>
      </div>
      <div className="CTA-section">
        <div className="CTA-container">
          <div className="CTA-text">
            <div className="CTA-heading">
              <div className="text-wrapper-3">Follow我地</div>
              <p className="p">
                唔會miss左最新動態 <br />
              </p>
            </div>
          </div>
          <div className="CTA-button" onClick={handleRedirectInstagram}>
            <div className="text-wrapper-9">
              {/* <a href="https://www.instagram.com/caelusium/?igshid=NzZlODBkYWE4Ng%3D%3D"> */}
              Instagram
              {/* </a> */}
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="text">Copyright © 2023 Caelusium</div>
        </div>
      </div>
    </div>
  );
};
