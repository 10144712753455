import React from "react";

const BackgroundVideo = ({ bgd }) => {
  return (
    <div className="background-video">
      <video autoPlay muted loop controls={false} playsInline className="video-box">
        <source src={bgd} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default BackgroundVideo;
