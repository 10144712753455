import React, { useEffect } from "react";
import "./style/card.css";
import useDailyCard from "../strapi/DailyCard.jsx";
import Spinner from "../spinner/Spinner.jsx";

function generateNumberBasedOnDate() {
  const currentDate = new Date();
  const dayOfYear = Math.floor((currentDate - new Date(currentDate.getFullYear(), 0, 0)) / 86400000);
  const number = dayOfYear % 11;
  return number;
}

export const Card = () => {
  const { fetchedData, loading, errorResult } = useDailyCard();
  const generatedNumber = generateNumberBasedOnDate();
  useEffect(() => {
    console.log("fetchedData:", fetchedData);
  }, [fetchedData]);
  if (loading || !fetchedData) {
    return (
      // <div className="spinner-overlay">
      //   <div className="spinner"></div>
      // </div>
      <Spinner />
    );
  }
  const currentDate = new Date();
  const month = currentDate.toLocaleString("default", { month: "short" });
  const date = currentDate.getDate();

  if (errorResult) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="tarot-screen">
      <div className="overlap">
        <div className="group-3">
          <div className="overlap-group-3">
            <div className="card-animation" />
            {/* <div className="vecteezy-tarot-2" alt="Vecteezy tarot" /> */}
            {/* <img
              src={"https://caelusium-local.agileexlab.net" + fetchedData?.data[0]?.attributes?.Cardimage?.data?.attributes?.url}
              alt={fetchedData?.data[0]?.attributes?.Card}
            /> */}
          </div>
        </div>
      </div>
      <div className="overlap-3">
        <div className="star-4" alt="Star" />
        <div className="overlap-4">
          <div className="line-9" alt="Line" />
          <div className="line-10" alt="Line" />
          <div className="line-11" alt="Line" />
          <div className="frame-5">
            <p className="p">
              <span className="text-wrapper-10">
                {date}
                <br />
              </span>
              <br />
              <span className="text-wrapper-11">{month}</span>
            </p>
            <div className="text-wrapper-12">有牌你算</div>
            <p className="text-wrapper-13">
              {fetchedData?.data[generatedNumber]?.attributes?.description}
            </p>
          </div>
          <div className="ellipse-2" />
        </div>
        <p className="membership-comes">
          {fetchedData?.data[generatedNumber]?.attributes?.card}
        </p>
      </div>
      {/* <div className="star-5" alt="Star" /> */}
      <div>
        <img
          src={
            "https://caelusium-local.agileexlab.net" +
            fetchedData?.data[generatedNumber]?.attributes?.image?.data?.attributes?.url
          }
          alt={fetchedData?.data[generatedNumber]?.attributes?.card}
          className="group-6"
        />
      </div>
      <div className="subtract" alt="Subtract" />
    </div>
  );
};
