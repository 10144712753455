import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Import the spinner CSS
import "./style/spinner.css";
import "./style/answer.css";
import useResult from "../strapi/Result.jsx";
import Spinner from "../spinner/Spinner.jsx";
const Answer = ({ answerCode }) => {
  const navigate = useNavigate();
  const { fetchedData, loading, errorResult } = useResult();
  useEffect(() => {
    console.log("fetchedData:", fetchedData);
  }, [fetchedData]);

  if (loading || !fetchedData) {
    return (
      <Spinner />
      // <div className="spinner-overlay">
      //   <div className="spinner"></div>
      // </div>
    );
  }

  if (errorResult) {
    return <div>Error: {errorResult.message}</div>;
  }

  const getImage = () => {
    let image = "";
    if (fetchedData?.data?.length > 0) {
      for (let i = 0; i < fetchedData?.data?.length; i++) {
        if (fetchedData.data[i].attributes.code === answerCode) {
          image = fetchedData.data[i].attributes.image.data.attributes.url;
        }
      }
    }
    return image;
  };

  return (
    <div className={`col-lg-12 col-md-10 quiz-container`}>
      <div className="overlay-content">
        <div className="fade-in">
          <div className="answer-container">
            <div className="answer-title">同朋友分享你嘅小精靈啦！</div>
            <img
              src={`https://caelusium-local.agileexlab.net${getImage()}`}
              alt="This is result image"
              className="result-image"
              // onClick={handleImageClick}
            />
            <div className="buttonGroup">
              <button
                className="buttonAnswerContainer"
                onClick={() => {
                  navigate("/questions");
                }}
              >
                試多次？
              </button>
              <button
                className="buttonHomeContainer"
                onClick={() => {
                  navigate("/");
                }}
              >
                <div className="homeIconContainer">
                  <div className="homeIcon"></div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Answer;
