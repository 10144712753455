import { useState, useEffect, useContext } from 'react';    
import axios from 'axios';  
  
export default function useResult() {  
    const [error, setError] = useState('');  
    const [loading, setLoading] = useState(true);  
    const url =  
      'https://caelusium-local.agileexlab.net/api/results?populate=%2A&pagination[limit]=-1';   
    // console.log(process)  
    const apiKey = 'b2c5e792d4891a919d8843b0d4d4fe7d78b2344c51e30734ff519cdfde691da0bb11a5b3eea24fed12de254755584a68abe88161cb8413b8f37d0027fce8551fcf1d4e993eba54a12a1c014dd866531502c1a139a86afdbb71180e99ebeb902910a74be14ce2066c32eaa6f4e88795ce4f158f43a843ba2f08c8097fbb28794a';
    // console.log(apiKey)  
    const authentication = {  
      headers: {  
        Authorization:  
          'Bearer ' +  apiKey
      },  
    };  
    const [fetchedData, setFetchedData] = useState(null);  
  
    useEffect(() => {  
        const source = axios.CancelToken.source();  
    
        const getData = async () => {  
          try {  
            console.log("fetching result");
            const res = await axios.get(url, { ...authentication, cancelToken: source.token });  
            console.log(res);

            setFetchedData(res.data);
          } catch (err) {  
            if (axios.isCancel(err)) {  
              console.log('Request canceled', err.message);  
            } else {  
              setError(err);  
            }  
          } finally {  
            setLoading(false);
          }  
        };  
        getData();
        return () => {  
            source.cancel('Operation canceled by the user.');  
        };  
    }, []);  
  
    return { fetchedData, loading, error };  
}  

