/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer, useEffect } from "react";
import "./style/frame.css";
import useDailyCard from "../strapi/DailyCard.jsx";
import Spinner from "../spinner/Spinner.jsx";

function generateNumberBasedOnDate() {
  const currentDate = new Date();
  const dayOfYear = Math.floor((currentDate - new Date(currentDate.getFullYear(), 0, 0)) / 86400000);
  const number = dayOfYear % 11;
  return number;
}

export const Frame = ({ property1, className }) => {
  const generatedNumber = generateNumberBasedOnDate();
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  const { fetchedData, loading, errorResult } = useDailyCard();
  useEffect(() => {
    console.log("fetchedData:", fetchedData);
  }, [fetchedData]);
  if (loading || !fetchedData) {
    return (
      // <div className="spinner-overlay">
      //   <div className="spinner"></div>
      // </div>

      <Spinner />
    );
  }

  const currentDate = new Date();
  const month = currentDate.toLocaleString("default", { month: "short" });
  const date = currentDate.getDate();

  if (errorResult) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div
      className={`m-frame ${className}`}
      onClick={() => {
        dispatch("click");
      }}
    >
      <div className="div">
        <p className="element-may">
          <span className="text-wrapper">
            {month} {date}
            <br />
          </span>
          <br />
          {/* <span className="span">Dec</span> */}
        </p>
        <div className="text-wrapper-2">有牌你算</div>
      </div>
      <div className="div-2">
        {state.property1 === "default" && (
          <div className="group">
            <img
              src={
                "https://caelusium-local.agileexlab.net" +
                fetchedData?.data[generatedNumber]?.attributes?.image?.data?.attributes
                  ?.url
              }
              alt={fetchedData?.data[generatedNumber]?.attributes?.card}
              className="rectangle"
            />
            <div className="rectangle" alt="Rectangle" />
          </div>
        )}

        {state.property1 === "variant-2" && (
          <>
            <div className="rectangle-2" />
            <div className="div-3">
              <div className="text-wrapper-3">Tarot Readings</div>
              <p>
                <span className="text-wrapper-4">{month}</span>
                <span className="text-wrapper-5"> {date}</span>
              </p>
              <div className="text-wrapper-6">
                {fetchedData?.data[generatedNumber]?.attributes?.card}
              </div>
              <p className="a-common-aspect-to">
                {fetchedData?.data[generatedNumber]?.attributes?.description}
              </p>
            </div>
          </>
        )}
      </div>
      <div className="div-4">
        <div className="div-wrapper">
          <div className="text-wrapper-7">
            {fetchedData?.data[generatedNumber]?.attributes?.card}
          </div>
        </div>
      </div>
    </div>
  );
};

function reducer(state, action) {
  if (state.property1 === "default") {
    switch (action) {
      case "click":
        return {
          property1: "variant-2",
        };
    }
  }

  if (state.property1 === "variant-2") {
    switch (action) {
      case "click":
        return {
          property1: "default",
        };
    }
  }

  return state;
}

Frame.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  rectangle: PropTypes.string,
  star: PropTypes.string,
  img: PropTypes.string,
  star1: PropTypes.string,
  star2: PropTypes.string,
};
