import React, { useEffect } from "react";
import "../../styles.css";
import "./style/spinner.css";
import { useNavigate } from "react-router-dom";
import "./style/aboutUs.css";

const AboutUs = () => {
  const navigate = useNavigate();

  const handleRedirectHome = () => {
    navigate("/");
  };

  const handleRedirectQuiz = () => {
    navigate("/quiz");
  };

  const handleRedirectAboutUs = () => {
    navigate("/aboutUs");
  };

  return (
    <div className="aboutUsContainer">
      <div className="homeButton"><button
                className="buttonHomeContainer"
                onClick={() => {
                  navigate("/");
                }}
              >
                <div className="homeIconContainer">
                  Home
                </div>
              </button></div>
      <div className="navbar-logo-left">
        <div className="navbar-container">
          <div className="navbar-content">
            <div className="brand-logos">
              <div className="text-wrapper" onClick={handleRedirectHome}>
                <div className="company-logo logo-wrapper"></div>
              </div>
            </div>
            <div className="navbar-menu">
              <div className="navbar-link">
                <div className="navbar-div">算命</div>
              </div>
              <div className="navbar-link">
                <div className="navbar-div">塔羅</div>
              </div>
              <div className="navbar-link">
                <div className="navbar-div" onClick={handleRedirectAboutUs}>
                  關於我地
                </div>
              </div>
              <div className="navbar-button">
                <div className="text-wrapper-2" onClick={handleRedirectQuiz}>
                  開始測驗
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="aboutUsTitle">關於我地</div>
      <div className="logoContainer"><div className='logo'></div></div>
      
      <div className="aboutUsContent">
        <div className="aboutUsSentence">你是否想知道生命中的轉折點背後的意義，Calesium將帶領你從塔羅牌中找到答案。</div><br/>
        <div className="aboutUsSentence">歡迎來到Caelusium，一個專注於塔羅牌解讀的AI人工智能助手。我的使命是幫助人們找到希望和答案，並提供專業的塔羅牌解讀服務。</div><br/>
        <div className="aboutUsSentence">Caelusium的故事始於對塔羅的無窮熱愛。我們深深被塔羅的神秘和靈性吸引，但我們想要將其帶入21世紀，進入一個全新的領域。這是一場旅程，從傳統走向現代，融合了科技和靈性，打開了一扇通往未知的門。這是因為Caelusium結合了拉丁語中的「Caelus」（天空）呼應著我們對夢想、靈性和未知的探索。這個名字不僅具有美感，還表達了我們致力於將傳統和現代融合的使命。它是我們對未來的遠大願景的象徵，同時營造了一種神秘而吸引人的氛圍。Caelusium不僅是一個名字，更是一個象徵著夢想、智慧和未知的品牌。</div></div>
    </div>
  );
};

export default AboutUs;
