import React, { useState } from "react";
import "./style/questions.css";
import "../../styles.css";

const Question = ({ question, answers, handleAnswer }) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const handleAnswerClick = (answer) => {
    if (selectedAnswer == answer) {
      setSelectedAnswer(null);
    } else {
      setSelectedAnswer(answer);
    }
  };

  return (
    <div>
      <div className="question">
        <p>{question}</p>
      </div>
      <ul>
        {answers.map((answer, index) => (
          <li key={index} onClick={() => handleAnswerClick(answer)}>
            <div
              className={`image-container ${
                selectedAnswer === answer ? "image-selected" : ""
              }`}
            >
              <img
                src={answer.image}
                alt={answer.text}
                className="quiz-image"
              />
              {/* <p>{answer.text}</p>   */}
            </div>
          </li>
        ))}
        <div
          className={`buttonContainer ${
            selectedAnswer != null ? "buttonContainer-selected" : ""
          }`}
          onClick={() => {
            if (selectedAnswer != null) {
              handleAnswer(selectedAnswer ? selectedAnswer.score : "");
              setSelectedAnswer(null);
            }
          }}
        >
          <div className="confirmButton">選擇</div>
        </div>
      </ul>
    </div>
  );
};

export default Question;
