import '../ArticlePageLayout.css';
import React, { useEffect } from 'react';
// import '../../../../common/scss/Common.css';
// import '../../../../common/scss/LatestSwiper.css';
// import '../../../../common/scss/Category.css';
import ArticleContentContainerMobile from '../ArticleContentContainerMobile/ArticleContentContainerMobile';
import ArticleHeadingContainer from '../ArticleHeadingContainer/ArticleHeadingContainer';
import usePost from '../../../../components/strapi/Post';
// import { TopButton } from '../../../../common/TopButton';
import Spinner from '../../../../components/spinner/Spinner';

function ArticleContent() {
  const { fetchedData, loading, errorResult } = usePost();
  useEffect(() => {    
    console.log('fetchedData:', fetchedData);    
  }, [fetchedData]);
  if (loading || !fetchedData) {  
    return (  
      // <div className="spinner-overlay">  
      //   <div className="spinner"></div>  
      // </div>  
      <Spinner/>
    );  
  }

  if (errorResult) {    
    return <div>Error: {error.message}</div>;    
  }  
  return (
    <div className='Page-Content Article-bottom-padding'>
      {/* <div className='Top-Spacing-Row'></div> */}
      <div className='background-limit'>
        <div className='Article-Page-Wrapper' >
          <div className='Content-Container Article-Page-Content'>
              <ArticleHeadingContainer content={fetchedData?.data[0]} />
              <ArticleContentContainerMobile content={fetchedData?.data[0]} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArticleContent;
