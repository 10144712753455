import React, { useState } from "react";
import { ErrorMessage, useField } from "formik";
// import "../../resources/signUp.css";
import "./style/textfield.css"

const TextField = ({ label, dob = "false", ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [prevValue, setPrevValue] = useState("");

  const handleChange = (event) => {
    let value = event.target.value;
    if (value.length < prevValue.length) {
      helpers.setValue(value);
      setPrevValue(value);
      return;
    }
    if (value.length === 2 || value.length === 5) {
      value += "/";
    }
    helpers.setValue(value);
    setPrevValue(value);
  };

  return (
    <div
      className={`mb-2 textFieldMargin ${
        meta.touched && meta.error && "textFieldError"
      }`}
    >
      <input
        className={`form-control shadow-none inputField ${
          meta.touched && meta.error && "inputError"
        }`}
        {...field}
        {...props}
        autoComplete="off"
        onChange={dob === "true" ? handleChange : field.onChange}
      />
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  );
};

export default TextField;
