import React, { Component } from "react";
import "../../styles.css";
import "./style/signup.css";
import BackgroundVideo from "../../components/background_video/BackgroundVideo.jsx";
import { Link } from "react-router-dom";
import TextField from "../../components/textField/textField.jsx";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const SignUp = () => {
  const validate = Yup.object({
    dob: Yup.string()
      .matches(
        /^(?:0[1-9]|[12]\d|3[01])([\/.-])(?:0[1-9]|1[012])\1(?:19|20)\d\d$/,
        "Date of Birth is not valid"
      )
      .required("Date of Birth is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
  });

  const initials = {
    dob: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  };

  return (
    <>
      <div>
        <BackgroundVideo bgd="https://caelusium-local.agileexlab.net/uploads/29_f59294cb47.mp4" />
        <div className="formContainer">
          <Formik
            initialValues={initials}
            validationSchema={validate}
            onSubmit={(values) => {
              alert(JSON.stringify(values, null, 2));
            }}
          >
            {(formik) => (
              <>
                <div className="signUpTitle">Sign Up to Get Sorted</div>

                <Form className="inputForm" onSubmit={formik.handleSubmit}>
                  <label className="inputLabel doubleLine" htmlFor="dob">
                    DATE OF BIRTH
                  </label>
                  <TextField
                    type="text"
                    id="dob"
                    name="dob"
                    placeholder="DD/MM/YYYY"
                    dob="true"
                  />

                  <label className="inputLabel" htmlFor="email">
                    EMAIL ADDRESS
                  </label>
                  <TextField id="email" name="email" type="email" />

                  <label className="inputLabel" htmlFor="firstName">
                    PASSWORD
                  </label>
                  <TextField id="password" name="password" type="text" />

                  <div className="nameInputContainer">
                    <div>
                      <label
                        className="inputLabel doubleLineName"
                        htmlFor="firstName"
                      >
                        FIRST NAME
                      </label>
                      <TextField id="firstName" name="firstName" type="text" />
                    </div>

                    <div>
                      <label
                        className="inputLabel doubleLineName"
                        htmlFor="lastName"
                      >
                        LAST NAME
                      </label>
                      <TextField id="lastName" name="lastName" type="text" />
                    </div>
                  </div>
                  <div className="signUpButton linkButton" type="submit">
                    Sign Up
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default SignUp;
