import React from "react";
import { Frame } from "../frame/Frame";
import "./style/landingMobile.css";
import { useNavigate } from "react-router-dom";

export const LandingMobile = () => {
  const navigate = useNavigate();

  const handleRedirectQuiz = () => {
    navigate("/quiz");
  };

  const handleRedirectInstagram = () => {
    window.open(
      "https://www.instagram.com/caelusium/?igshid=NzZlODBkYWE4Ng%3D%3D"
    );
  };

  return (
    <div className="m-iphone-pro">
      <div className="m-div-5">
        <div className="m-overlap">
          <div className="m-frame-2">
            <div className="m-CTA-container">
              <div className="m-CTA-text">
                <div className="m-CTA-heading">
                  <div className="m-join-the-community">Follow我地</div>
                  <p className="m-start-transforming">唔會miss左最新動態</p>
                </div>
              </div>
              <div className="m-CTA-button" onClick={handleRedirectInstagram}>
                <div className="m-text-wrapper-8">
                  {/* <a href="https://www.instagram.com/caelusium/?igshid=NzZlODBkYWE4Ng%3D%3D"> */}
                  Instagram
                  {/* </a> */}
                </div>
              </div>
            </div>
            <div className="m-copyright">
              <div className="m-text">Copyright © 2023 Caelusium</div>
            </div>
          </div>
        </div>
        <div className="m-overlap-2">
          <Frame
            className="m-frame-7154"
            img="/img/star-16-1.svg"
            property1="default"
          />
          <div className="m-frame-9">
            <div className="m-card-text">
              <div className="m-img-2m" alt="The next generation" />
              <div className="text-wrapper-title">次世代塔羅占卜</div>
              <p className="m-text-wrapper-12">
                凡人可以貌相, 天機不可泄漏 <br />
                {/* 凡人不可貌相，天機不可泄漏。<br/> */}
                跟住我哋，攞個準準嘅塔羅占卜，就可以揭開你人生嘅神秘面紗啦！我哋嘅塔羅師唔止準確，仲有幽默感，一齊嚟瞓覺都可以笑到肚痛！你唔知自己未來會點，我哋就係你嘅神秘導航，帶你去探索運氣大洋！唔好錯過，快啲嚟挖掘屬於你嘅塔羅之旅啦！
              </p>
            </div>
            <div className="m-IMAGE">
              <div className="m-rectangle-7m" alt="Rectangle" />
            </div>
            <div className="m-navbar-button">
              <div className="m-text-wrapper-2" onClick={handleRedirectQuiz}>
                開始測驗
              </div>
            </div>
          </div>
          <div className="m-frame-11">
            <div className="m-frame-12">
              <div className="m-text-wrapper-14">
                <div className="company-logo logo-wrapper"></div>
              </div>
              <div className="m-img-21m" alt="Frame" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
