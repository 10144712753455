import ArticleContent from './component/ArticleContent/ArticleContent';
import React from 'react';

function ArticlePage() {
  return (
    <div className='Page-Container'>
      <ArticleContent />
    </div>
  );
}

export default ArticlePage;
