import React from 'react';  
import './style/spinner.css';  
  
const Spinner = () => (  
  <div className="spinner-overlay">  
    <div className="spinner"></div>  
    {/* <img className="mylogo" width="80px"  /> */}
  </div>  
);  
  
export default Spinner;  
