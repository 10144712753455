import React, { Component, useEffect } from "react";
import "../../styles.css";
import "./style/title.css";
import BackgroundVideo from "../../components/background_video/BackgroundVideo.jsx";
import useBackgroundVideo from "../../components/strapi/BackgroundVideo.jsx";  
import { Link } from "react-router-dom";
import Spinner from "../../components/spinner/Spinner.jsx";
// class Title extends React.Component {

const Title = () => {  
  const { fetchedData, loading, error } = useBackgroundVideo();  

  useEffect(() => {  
    console.log('fetchedData:', fetchedData);  
  }, [fetchedData]);
  
  if (loading || !fetchedData) {  
    return (
      // <div className="spinner-overlay">  
      //   <div className="spinner"></div>  
      // </div>  
      <Spinner/>
    );  
  }  
  
  if (error) {    
    return <div>Error: {error.message}</div>;    
  }  
  return (
    <>
      <BackgroundVideo bgd={"https://caelusium-local.agileexlab.net" + fetchedData.data[0]?.attributes.video.data.attributes.url} />  
      <div className="container">
        <div className="descriptionContainer">
          <p className="descriptionParagraph">
            聖誕夜裡,小精靈正準備送出禮物。但似乎有一隻
            小精靈走失了,牠需要你的幫助!
          </p>
          <p className="descriptionParagraph">
            出發吧,利用你的直覺和觀察力,在家中各個角落尋找線索。牠可能躲在聖誕樹下,也可能藏在窗戶後面。留意你周圍有沒有看到一抹小小的光芒?
            或是聽到細小的笑聲?
          </p>
          <p className="descriptionParagraph">
            除了留意視覺和聽覺外,你也可以用心思感受小精靈的存在。相信你的感覺,一定可以找到牠!找到後請拉著牠的小手,把牠安全送回同伴那裡,讓牠也能準時送出禮物。
          </p>
          <Link
            className="startButton descriptionParagraph linkButton"
            to="/questions"
          >
            開始
          </Link>
          {/* <Link className="startButton  linkButton" to="/signUp">
            signUp demo
          </Link> */}
        </div>
      </div>
    </>
  );
}

export default Title;
