import React, { useEffect } from "react";  
import Popup from "../../components/popup/Popup.jsx";  
import Quiz from "../../components/quiz/Quiz.jsx";
import "../../styles.css";  
import BackgroundVideo from "../../components/background_video/BackgroundVideo.jsx";  
import useBackgroundVideo from "../../components/strapi/BackgroundVideo.jsx";  
import './style/spinner.css';  
import Spinner from "../../components/spinner/Spinner.jsx";
  
const Main = () => {  
  const { fetchedData, loading, error } = useBackgroundVideo();  
  
  useEffect(() => {  
    console.log('fetchedData:', fetchedData);
  }, [fetchedData]);  
  
  if (loading || !fetchedData) {  
    return (  
      // <div className="spinner-overlay">  
      //   <div className="spinner"></div>  
      // </div>  
      <Spinner/>
    );  
  }  
  
  if (error) {    
    return <div>Error: {error.message}</div>;    
  }  
  
  return (  
    <div className="content-container">  
      <BackgroundVideo bgd={"https://caelusium-local.agileexlab.net" + fetchedData.data[0]?.attributes.video.data.attributes.url} />  
      <div className="row">  
        <Quiz></Quiz>  
      </div>  
    </div>  
  );  
};  
  
export default Main;  
