import { useState } from 'react';
// import '../ArticlePageLayout.css';
import React from 'react';
// import { ImageSwiper } from '../../../../common/ImageSwiper';
// import '../../../../common/scss/Common.css';
// import '../../../../common/scss/LatestSwiper.css';
// import '../../../../common/scss/Category.css';
import ReactMarkdown from 'react-markdown';

function ArticleContentContainerMobile(props) {
  let headingCount = props.content.attributes.media.data.length;

  const [currentHeaderIndex, setCurrentHeaderIndex] = useState(0);
  
  const scrollHandler = (event) => { // TODO: change mechanism (portion 1/headingCount)
    const contentHeight = event.target.scrollHeight - event.target.clientHeight;
    const scrollPosition = event.target.scrollTop;
    if (scrollPosition < contentHeight) {
      const imageIndex = Math.floor((scrollPosition / contentHeight) * headingCount);
      setCurrentHeaderIndex(imageIndex); // next image
    }
  };

  let portraitImgList = []
  if (props.content.attributes.media) {
    props.content.attributes.media.data.map((img) => {
      portraitImgList.push(img)
    })
    portraitImgList.reverse()
  }

  return (
    <>
      {props.content.attributes ? (
        <div className='Article-Content-Container-mobile' >
          {props.content.attributes.title.includes("現場直播")?
          (
          <>
          <div className='Article-Content'>
            <div className='Article-Content-Col'>
              <div className='Image-Column'>
                <div className='Image-Swiper' >
                  {/* <ImageSwiper
                    media={portraitImgList}
                    headingCount={headingCount + 1}
                    imgIndex={currentHeaderIndex}
                  /> */}
                  <iframe src="//player.louisvuitton.com/embed/1486109" title="Louis Vuitton - Pre-Fall 2024 Men - Hong Kong - 16:9 Monobitrate" width="100%" height="150%" style={{ height: '30vh', border: 0, left: 0, top: 0, overflow: 'auto' }} allowFullScreen></iframe>
                </div>
              </div>
              <div
                className={`Text-Column Colored-Scroll` }
                onScroll={scrollHandler}
              >
                <div className='Article-Text-Title'>
                </div>
                <div className='Article-Text-Content'>
                  
                  {props?.content?.attributes ? (
                    <ReactMarkdown
                      children={props.content.attributes.content}
                      className={'React-Markdown-HTML Content-With-Headings'}
                      includeElementIndex={true}
                      components={{
                        h2: ({ node, ...props }) => (
                          <h2 className={`${props.index}`} {...props} />
                        ),
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          </>
          ):(
            <div className='Article-Content'>
            <div className='Article-Content-Col'>
              <div className='Image-Column'>
                <div className='Image-Swiper' >
                  {/* <ImageSwiper
                    media={portraitImgList}
                    headingCount={headingCount + 1}
                    imgIndex={currentHeaderIndex}
                  /> */}
                </div>
              </div>
              <div
                className={`Text-Column Colored-Scroll`}
                onScroll={scrollHandler}
              >
                <div className='Article-Text-Title'>
                </div>
                <div className='Article-Text-Content'>
                  
                  {props?.content?.attributes ? (
                    <ReactMarkdown
                      children={props.content.attributes.content}
                      className={'React-Markdown-HTML Content-With-Headings'}
                      includeElementIndex={true}
                      components={{
                        h2: ({ node, ...props }) => (
                          <h2 className={`${props.index}`} {...props} />
                        ),
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default ArticleContentContainerMobile;
