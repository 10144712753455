import React, { useEffect } from "react";
import Popup from "../../components/popup/Popup.jsx";
import Quiz from "../../components/quiz/Quiz.jsx";
import "../../styles.css";
import BackgroundVideo from "../../components/background_video/BackgroundVideo.jsx";
import useBackgroundVideo from "../../components/strapi/BackgroundVideo.jsx";
import "./style/spinner.css";
import Spinner from "../../components/spinner/Spinner.jsx";
import TextField from "../../components/textField/textField.jsx";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./style/result.css";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

const Result = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const score = location.state.score;

  const validate = Yup.object({
    email: Yup.string().email("錯誤電子信箱格式").required("請輸入電子信箱"),
  });

  const initials = {
    email: "",
  };

  const { fetchedData, loading, error } = useBackgroundVideo();

  useEffect(() => {
    console.log("fetchedData:", fetchedData);
  }, [fetchedData]);

  if (loading || !fetchedData) {
    return (
      // <div className="spinner-overlay">
      //   <div className="spinner"></div>
      // </div>
      <Spinner />
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const apiKey =
    "521e2faed9602381c8f6026c3ba8d02fe6988a16f0ec50a4300f2c160c2e33e64d0f4b2d2d29a965753c8f0b5e9fa61e81be7a8c1cfe04860514550e15ee484acbb27c7672968832b9a10058f900e10f35dcdf0224afef222cee64eab7933f7c1c8ff9a76f5c363d69fafbd7ad3db248500916c0be16b3a6c81e2059f926b9b9";
  // console.log(apiKey)
  const authentication = {
    headers: {
      Authorization: "Bearer " + apiKey,
    },
  };

  return (
    <div className="content-container">
      <BackgroundVideo
        bgd={
          "https://caelusium-local.agileexlab.net" +
          fetchedData.data[0]?.attributes.video.data.attributes.url
        }
      />

      <div className="formContainerResult">
        <div className="formTitleResult">
          輸入電子信箱，發現屬於自己嘅小精靈！
        </div>
        <Formik
          initialValues={initials}
          validationSchema={validate}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(async () => {
              try {
                const data = await axios.post(
                  "https://caelusium-local.agileexlab.net/api/subscriptions",
                  {
                    data: {
                      email: values.email,
                      firstname: "",
                      lastname: "",
                      receive_update: true,
                      membership: "free",
                    },
                  },
                  authentication
                );
              } catch (err) {
                if (axios.isCancel(err)) {
                  console.log("Post canceled", err.message);
                } else {
                  console.log(err);
                }
              } finally {
                navigate("/answer", { state: { score } });
                setSubmitting(false);
              }
            }, 400);
          }}
        >
          {({ isSubmitting }) => (
            <>
              <Form>
                <label className="inputLabelResult" htmlFor="email">
                  電子信箱
                </label>
                <TextField id="email" name="email" type="email" />
                <button
                  className="signUpButton linkButton"
                  type="submit"
                  disabled={isSubmitting}
                >
                  立刻發現小精靈！
                </button>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Result;
