import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./styles.css";

import Header from "./components/header/Header.jsx";
import Main from "./pages/main/Main.jsx";
import Title from "./pages/title/Title.jsx";
import Footer from "./components/footer/Footer.jsx";
import SignUp from "./pages/signUp/SignUp.jsx";
import { Landing } from "./components/landing/Landing.jsx";
import { LandingMobile } from "./components/landing_mobile/LandingMobile.jsx";
import Result from "./pages/result/Result.jsx";
import AnswerPage from "./pages/answerPage/AnswerPage.jsx";
import AboutUs from "./pages/aboutUs/AboutUs.jsx";

import ArticlePage from "./pages/article/ArticlePage.jsx";
class App extends React.Component {
  constructor() {
    super();
    this.state = {
      screenSize: this.checkScreenSize(),
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const screenSize = this.checkScreenSize();
    this.setState({ screenSize });
  };

  checkScreenSize() {
    // Get the current window width
    const windowWidth = window.innerWidth;

    // Define the breakpoint width for mobile
    const mobileBreakpoint = 768;

    // Check if the window width is less than the mobile breakpoint
    if (windowWidth < mobileBreakpoint) {
      return "mobile";
    } else {
      return "desktop";
    }
  }

  render() {
    const { screenSize } = this.state;
    return (
      <BrowserRouter>
        <div className="App">
          {/* <Header /> */}
          <Routes>
            {/* TODO: login page and 'alrdy hav acc?' */}
            <Route
              path="/"
              element={
                screenSize === "mobile" ? <LandingMobile /> : <Landing />
              }
              exact
            />
            <Route path="/quiz" element={<Title />} exact />
            {/* <Route path="/template" element={<FigmaTemplateOne />} exact /> */}
            <Route path="/signUp" element={<SignUp />} exact />
            <Route path="/article" element={<ArticlePage />} exact />
            <Route path="/questions" element={<Main />} />
            <Route path="/result" element={<Result />} />
            <Route path="/answer" element={<AnswerPage />} />
            <Route path="/aboutUs" element={<AboutUs />} />
          </Routes>
          {/* <Footer /> */}
        </div>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
