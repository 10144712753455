import React, { useEffect } from "react";
import "../../styles.css";
import BackgroundVideo from "../../components/background_video/BackgroundVideo.jsx";
import useBackgroundVideo from "../../components/strapi/BackgroundVideo.jsx";
import "./style/spinner.css";
import Spinner from "../../components/spinner/Spinner.jsx";

import "./style/answer.css";
import { useNavigate, useLocation } from "react-router-dom";
import Answer from "../../components/answer/Answer.jsx";

const AnswerPage = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const score = location.state.score;

  const { fetchedData, loading, error } = useBackgroundVideo();

  useEffect(() => {
    console.log("fetchedData:", fetchedData);
  }, [fetchedData]);

  if (loading || !fetchedData) {
    return (
      // <div className="spinner-overlay">
      //   <div className="spinner"></div>
      // </div>
      <Spinner />
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="content-container">
        <BackgroundVideo
          bgd={
            "https://caelusium-local.agileexlab.net" +
            fetchedData.data[0]?.attributes.video.data.attributes.url
          }
        />
        <Answer answerCode={score} />
      </div>
    </>
  );
};

export default AnswerPage;
